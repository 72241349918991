@import '../../../../styles/_index';

.yellow {
    @include button-base;

    &:hover {
        @include button-base ($background: var(--accent-variant), $border-color: var(--accent-variant)); }

    &:click {
        @include button-base ($background: var(--accent-variant), $border-color: var(--accent-variant)); } }


.black {
    @include button-base($color: var(--white), $background: var(--black));

    &:hover {
        @include button-base($color: var(--white), $background: var(--alpha-grey), $border-color: var(--alpha-grey)); }

    &:click {
        @include button-base($color: var(--white), $background: var(--alpha-grey), $border-color: var(--alpha-grey)); } }


.dark-white {
    @include button-base($background: var(--dark-white));

    &:hover {
        @include button-base ($background: var(--light-grey), $border-color: var(--black)); }

    &:click {
        @include button-base($background: var(--white), $border-color: var(--black)); } }

.green {
    @include button-base($background: var(--primary)); }

.white {
    @include button-base($background: var(--white)); }

.no-color {
    @include button-base($background: none); }

