.menu-container-header {
    background: transparent;
    color: var(--alpha-grey);
    font-weight: bold;
    padding-left: 60px;

    &:focus {

        color: var(--accent); } }

.icon-profile {
    font-size: 20px; }
