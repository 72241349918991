.container-kpi{
display: flex;
justify-content: space-between;
 margin: 20px 0px;
}

.card-kpi{
    position: relative;
    background-color: white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.value-kpi{
    font-size: 20px;
    width: 100%; 
    text-align: center;
}

.container-colorLabel{
    position: absolute;
    left: 12px;
    bottom: 4px;
}

.container-info-client{
    background-color: white;
    border-radius: 25px;
    width: auto;
    max-height: 125px;
     padding: 15px
}