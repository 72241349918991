.welcome-text {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 80vh; }

.ant-result-success .ant-result-icon > .anticon {
    color: var(--primary); }

.ant-result-title {
    font-size: 30px; }

.ant-result-subtitle {
    font-size: 18px; }
