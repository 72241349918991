.tableSG .ant-table-tbody {
    background: var(--light-grey);
    box-shadow: 0px 2px 13px rgba(204, 213, 215, 0.2);
    border-radius: 50px; }

.ant-table-tbody > tr > td {
    border-top: 8px solid var(--white); }

.tableSG .ant-table-tbody > tr.ant-table-row:hover > td {
    background: var(--light-accent);
    color: var(--black); }

