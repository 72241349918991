.card-metal {
    width: 60%;
    position: absolute;
    min-height: 93%;
    overflow: hidden;
    top: 10%;
    left: 42%;
    border-radius: 20px 0 0 0;
    box-shadow: -10px -10px 10px rgba(0, 0, 0, 0.03);
}

.no-row-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 500px;
    overflow: hidden;
    text-align: center;


    >h1 {
        color: var(--grey);
        font-size: 18px;
    }

}