@mixin box-border-shadow () {
  box-shadow: var(--shadow);
  border: var(--border-input); }

@mixin button-base ($color: var(--black), $background: var(--accent), $border-color: transparent) {
  color: $color !important;
  background: $background !important;
  border-color: $border-color !important;
  font-weight: bold !important; }

@mixin input-base ($background: var(--white)) {
  background: $background;
  border-radius: 10px;
  border: var(--border-input);

  &:focus, &:active {
    @include box-border-shadow;

    &:hover {
      border: var(--border-input) !important; } } }
