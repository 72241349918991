
.headerComponent {
    background: var(--light-grey);
    padding: 0px 25px;
    box-shadow: var(--shadow);
    z-index: 10; }

.item-view {
    padding-left: 17px;
    font-weight: 600;
    color: #959494; }

.ant-dropdown {
    min-width: 202px !important; }

.border-top-dark-white {
    border-top: 1px solid var(--dark-white); }

.border-bottom-dark-white {
    border-bottom: 1px solid var(--dark-white); }

.flex {
    display: flex;
    justify-content: space-between; }


.flex-column {
    flex-direction: column; }

.align-items-center {
    align-items: center; }

.flex-center {
    display: flex;
    align-items: center; }

.jutify-space-between {
    justify-content: space-between; }

.accountOverlay-Container {
    position: absolute;
    right: 2%; }

.accountOverlay-notifications {
    flex-direction: column;
    gap: 15px;
    padding: 20px 11px;
    box-shadow: var(--shadow);
    background: var(--light-grey);
    margin-top: 10px;
    width: 30em;
    /* height: 60em; */
    max-height: 91vh;

    p {
        color: var(--alpha-grey); }

    h5 {
        font-size: 18px; } }




.accountOverlay-UserData {
    flex-direction: column;
    gap: 15px;
    padding: 20px 40px;
    box-shadow: var(--shadow);
    background: var(--light-grey);
    margin-top: 10px;
    width: 300px;

    p {
        color: var(--alpha-grey); }

    h5 {
        font-size: 18px; } }

.center {
    width: 100%;
    display: flex;
    justify-content: center; }

.pd-t-10 {
    padding-top: 10px; }

.container-buton {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
    flex-direction: row-reverse;
    margin: 0; }

.button-form {
    margin-top: 9em; }

.box {
    height: 132px;
    width: 185px; }

.role {
    color: white;
    padding-top: 10px;
    padding-left: 10px; }

.title {
    font-size: 1.5em; }

.title-overlay {
    font-size: 1.1em; }

.profileDropdown {
    height: 20px;
    display: flex;
    align-items: center; }

.fw-bold {
    font-weight: bold; }

.fw-500 {
    font-weight: 500; }

.ant-dropdown-trigger {
    display: flex;
    align-items: center;
    gap: 10px; }

.flex-center {
    display: flex;
    justify-content: center; }

.container-logo {
    width: 50px; }

.container-circle-logo {
    width: 90%; }

.circle {
    background: var(--primary);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 28px; }

.button-change-password {
    background: none;
    color: var(--accent);
    border: none; }

.width-100 {
    width: 100%; }

.pl-7 {
    padding-right: 7px; }

.pr-7 {
    padding-left: 7px; }

.mt-5 {
    margin-top: 5px; }

.mb-5 {
    margin-bottom: 5px; }

.cursor {
    cursor: pointer; }

.message-error {
    color: red; }

.notification-overlay {
    margin-right: 8em; }

.notifications-list {
    display: flex;
    flex-direction: column;
    justify-content: center; }

.overflow {
    overflow: auto; }

.circle-notification {
    width: 0.7em;
    height: 0.7em;
    background-color: var(--accent-variant);
    margin-left: 0.6em;
    position: absolute;
    margin-top: -0.2em;
    border-radius: 50%;
    border: solid 1px #F9F9F9; }

.title-not {
    margin-left: 2em; }

.overlays-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em; }
