@import "../../../styles/_index";

.login-general {
    background: var(--accent);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center; }

.login-general-left {
    width: 90vh;
    height: 100vh;
    border-style: inset;
    border-right: 180px solid transparent;
    border-top: 100vh solid var(--black);
    border-bottom-width: 0;
    border-left-width: 0;

    .text-position {
        text-align: left;
        top: -70vh;
        position: relative;
        padding: 80px;

        p {
            color: var(--white);
            font-size: 18px;
            margin: 0;
            font-family: var(--font-family); }

        h1 {
            font-size: 80px;
            font-weight: bold;
            color: var(--white);
            margin: 0;
            font-family: var(--font-family); } } }

.login-general-right {
    display: flex;
    margin-left: 5%;
    background-color: transparent;
    border: none;
    font-weight: bold;
    h2 {
        font-size: 2em;
        margin: 0; }

    p {
        a {
            font-weight: bolder;
            text-decoration: underline;
            color: var(--black); } } }

.ant-input-affix-wrapper {
    @include input-base ($background: transparent);
    height: 40px;
    width: 400px;

    &:hover, &:active, &:focus {
        @include box-border-shadow (); }

    .ant-input {
        border: none;
        background: transparent;

        &::placeholder {
            color: var(--black); } } }

.missing-password {
    color: black;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    border: none;

    &:hover {
        color: var(--secundary); } }

// .ant-layout-footer
//     display: none
