.container-menu {
    background: transparent;
    height: 82vh;
    margin-top: 3vh; }

.side-menu {
    background: var(--light-grey);
    padding: 20px 10px 20px 10px;
    border-radius: 20px;
    width: 80vh;
    box-shadow: var(--shadow); }

.menu {
    background: '#F9F9F9';
    padding: 5px; }

.menu-item {
    font-weight: bold; }

.menu-drop {
    margin-left: 10px;
    margin-top: 35vh;
    font-size: 20px;
    color: var(--alpha-grey); }


.menu-icon {
    font-size: 20px !important;
    margin-right: 10px; }

.menu2-icon {
    font-size: 20px !important; }

.content {
    margin: 10px; }

.content-div {
    padding: 10px; }
