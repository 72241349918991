.market_price {
    color: black;
    border-color: grey;
    border-radius: 10px;
    font-weight: bold
}

.btn_rulePrice {
    border-radius: 10px;
    font-weight: bold;
    margin-left: 3px;
}