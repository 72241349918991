@import "../../../styles/_index";

.register-general {
    background: var(--primary);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center; }

.register-general-left {
    width: 90vh;
    height: 100vh;
    border-style: inset;
    border-right: 180px solid transparent;
    border-top: 100vh solid var(--black);
    border-bottom-width: 0;
    border-left-width: 0;


    .text-position-register {
        text-align: left;
        top: -60vh;
        position: relative;
        padding-left: 80px;

        p {
            color: var(--white);
            font-size: 18px;
            margin: 0;
            font-family: var(--font-family);

            .bold {
                font-weight: bold; } }

        h1 {
            font-size: 80px;
            font-weight: bold;
            color: var(--white);
            margin: 0;
            font-family: var(--font-family); } } }

.register-general-right {
    display: flex;
    margin-left: 5%;
    background-color: transparent;
    border: none;
    font-weight: bold;

    .header-register-form {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 2em;
            margin: 0; } } }

.register-form {
    margin-top: 5%;

    .ant-input {
        @include input-base ($background: transparent);
        height: 40px;
        width: 400px;
        align-content: center;

        &:hover, &:active, &:focus {
            box-shadow: var(--shadow); }

        &::placeholder {
            color: var(--black); } }

    .disabled {
        background: var(--alpha-grey);
        color: var(--white); } }
