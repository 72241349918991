.pl-20{
  padding-left: 20px;
}

.mb-0{
  margin-bottom: 0px;
}

.desc{
  color: #c4bfbfb6;

}

.content-totals {

  display: flex;
  flex-direction: column;

}
.margin-week {
  margin-top: 2.55em;
}
.padding-totals{
  margin-top: 2em;
  padding-left: 2em !important;
}
.margin-data{
  margin-top: .5em;
}
.nuber-size{
  font-size: 2em;
}

.content-padding {
  padding-left: 7em !important;
}

.content-totals-ini{
  margin-left: 5em;

}
.content-totals{
  h1{
    color: #b6b4b4;
  }
  span {
    font-size: 2em;
    font-weight: 500;
  }
}
.content-pie-chart{
  margin-top: 2.5em !important;
}
.week-title{
  font-size: 2em;
  font-weight: 500;
}
.department-title{
  font-size: 1.2em;
}