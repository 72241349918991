.home-container {
    width: 100%;
    height: 92vh;
    display: flex; }

.content-notification {
    display: flex;
    justify-content: flex-end;
    width: 40%; }

.content-all {
    width: 60%; }

.notifications {
    width: 40em;
    height: 100%;
    box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.38);
    -webkit-box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.38);
    -moz-box-shadow: -1px 1px 5px 0px rgba(0,0,0,0.38); }


.notification-header {
    width: 100%;
    height: 3.5em;
    display: flex;
    color: aqua;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: black;
    border-bottom: solid 1px #ACACAB; }

.notifications-list {
    display: flex;
    flex-direction: column;
    justify-content: center; }

.icon-notification {
    margin-right: 2em;
    font-size: 1.2em; }

.accountOverlay-UserData {
    flex-direction: column;
    gap: 15px;
    padding: 20px 40px;
    box-shadow: var(--shadow);
    background: var(--light-grey);
    margin-top: 10px;
    width: 450px; }

.notification-item {
    display: flex;
    height: 5em;
    width: 100%; }

.container-notification-items {
    width: 100%; }

.status {
    width: 3em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.body {
    width: 100%;
    height: 100%;
    padding-left: 1em; }

.title-notification {
    font-size: 1.2em;
    font-weight: 600; }

.icon-circle {
    width: 0.7em;
    height: 0.7em;
    border-radius: 50%; }

.count-notification {
    width: 1em;
    height: 1em;
    background-color: #EA9E0C;
    position: absolute;
    border-radius: 50%;
    left: 0.5em;
    top: 0.5em;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500; }

.spinner {
    margin-top: 2em; }


