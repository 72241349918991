@import '../../../../styles/_index';
.botDrawer {
    font-size: 26px;
    color: var(--alpha-grey);
    padding: .6em;
    border-radius: 50px;
    background: var(--white) !important;
    z-index: 1000;
    height: 100px;
    box-shadow: var(--shadow); }
