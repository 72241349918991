@import '../../../../styles/_index';
p {}
.short_Text {
  width: 20vh;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.name {
  font-weight: bold; }

.select-weight {
  font-weight: bold; }

.icon-action {
  cursor: pointer;
  font-size: 1.5em; }

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715; }

[data-theme='compact'] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667; }

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715; }

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667; }

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85); }

.ant-form-vertical .ant-form-item-label > label {
  height: auto;
  font-weight: 600;
  margin-left: 6px; }

.title-product {
  font-size: 1.5em; }

.container-data {
  margin-top: .7em; }

.item-view-product {
  height: 2.1em;
  padding-left: 17px;
  font-weight: 600;
  margin-top: 0.3em;
  color: #959494; }

.container-buton {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  flex-direction: row-reverse;
  margin: 0; }

.item-view-product-3 {
  height: 4em !important; }

.content-item-view {
  margin-top: .5em; }

.label-item-view {
  font-weight: 600;
  margin-left: 6px; }

.button-form {
  margin-right: .5em; }
.camel-case {
  text-transform: capitalize; }

.ver-mas {
  color: var(--accent-variant);
  font-size: .7em;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 10em;
  cursor: pointer;
  padding-right: 2em; }

.ver-mas-edit {
  padding-right: 5em !important; }

.scroll {
  width: 15em;
  height: 4.5em;
  overflow-y: auto; }

.no-scroll {
  height: 4.5em;
  overflow-y: auto; }

.container-urls {
  @include button-base($background: var(--light-primary));
  border-radius: 20px;
  text-align: center;
  font-weight: bold; }

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
