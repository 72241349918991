@import "./_index";


.ant-modal-body {
    background: var(--light-grey); }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    border: none;
    border-radius: 10px; }

.ant-input, .ant-select, .ant-picker, .ant-input-number {
    @include input-base; }


.ant-input-number-disabled, .ant-select-disabled {
    background: #f5f5f5;
    borderRadius: 10px; }

.ant-select-status-error {
    border-color:red !important {} }
