@import '../../../../styles/_index';

.ant-drawer-header {
    border-bottom: none;
    background: transparent;
    height: 120px;
    border-top: 5px solid var(--primary); }

.ant-drawer-title {
    font-family: var(--font-family);
    font-weight: bolder;
    font-size: 20px; }

.ant-drawer-body {
    background: var(--light-grey);
    box-shadow: var(--shadow); }
