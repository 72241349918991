@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');

:root {
  --white: #ffffff;
  --black: #221F1F;
  --alpha-grey: #474747;
  --primary: #00C39A;
  --light-primary: #aaebdd;
  --accent: #FDCF6D;
  --accent-variant: #EA9E0C;
  --light-accent: #FFF5E2;
  --light-grey: #F9F9F9;
  --grey:#c3c3c3;
  --green: #b7eb8f;
  --dark-white: #dddddd;
  --font-family: 'Montserrat', sans-serif;  
  --font-size: 14px;
  --shadow: 5px 0px 16px -5px rgba(0, 0, 0, 0.4);
  --border-input: 1px solid var(--grey);
  --border-input-bold: 2px solid var(--black);
  }

  :root {
    --swiper-theme-color: black !important;
  }

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1280px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#smallDevice {
  display: none;
}

.campo-vacio {
  border: 1px solid var(--accent-variant);
  box-shadow: 0px 0px 4px var(--accent-variant);
}

@media (max-width: 500px) {

  #largeDevice {
    display: none;
  }

  #smallDevice {
    display: block;
  }
}


.table-header{
  display: flex;
  align-content: center;
  align-items: center;  
  justify-content: space-between;
  padding: 0 20px;
}

.title-summary{
  font-size: 2.5em;
}

.title-chart{
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 20px !important;
}

.title-chart-secondary { 
  font-size: 1.2em;
  color: grey;
  padding-bottom: 20px !important;
}

.totals-header{
  display: flex;  
}

.kpi {  
  border-radius: 5px;
  width: 100px;
  font-size: 5px;
}

.ant-menu-item-selected a {
  color: var(--accent-variant) !important;
}

.ant-menu-item a:hover {
  color: var(--accent-variant) !important;
}

.ant-modal-footer {
  padding: 0px !important;
  background: var(--light-grey) !important;
}

.ant-table-filter-dropdown {
  border-radius: 10px !important;
}

.modify-icon{
  cursor:pointer;
  margin: 5px;
  color: var(--accent-variant)
}

.ant-collapse-header {
  align-items: center !important;
  display: flex;
}

.ant-checkbox-inner {
  border-color: #EA9E0C !important;
}


.anticon-info-circle{
  color: rgb(27, 164, 255) !important;
}

.selected-row,
.selected-row>.ant-table-column-sort {
    background-color: var(--light-accent)
}